import React, { useState, createContext } from "react"

// Create the context
export const GlobalContext = createContext()

export function MenuProvider({ children }) {
  // Place state in here
  const [isOpen, setNav] = useState(false)
  // Iubenda iframe
  const [contentModelIubenda, setContentModelIubenda] = useState(null)
  const [openIubendaPolicy, setOpenIubendaPolicy] = useState(false)

  return (
    <GlobalContext.Provider
    value={{
      menu: [isOpen, setNav],
      iubendaContent: [contentModelIubenda, setContentModelIubenda],
      iubendaToggle: [openIubendaPolicy, setOpenIubendaPolicy],
    }}>
      {children}
    </GlobalContext.Provider>
  )
}