import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { MenuProvider } from "./src/components/MenuContext"
import { AnimatePresence } from "framer-motion"
import "@fontsource/heebo/400.css"
import "@fontsource/heebo/700.css"

function scriptIubenda(window,document) {
  var loader = () => {
    var s = document.createElement("script"),
    tag = document.getElementsByTagName("script")[0]
    s.src= "https://cdn.iubenda.com/iubenda.js"
    tag.parentNode.insertBefore(s,tag)
  }
  if(window.addEventListener){
    window.addEventListener("load", loader, false)
  }else if(window.attachEvent){
    window.attachEvent("onload", loader)
  }else{
    window.onload = loader
  }
}

export function wrapPageElement({ element }) {
  return <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
}

// Esecuzione script per integrazione Iubenda, policy
export const onClientEntry = () => {
  // console.log("ReactDOM.render has executed")
  scriptIubenda(window, document)
  // scriptGoogleAnalitycs(window)
}

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_CLIENT_API_KEY}>      
        <MenuProvider>
          {element}
        </MenuProvider>
    </GoogleReCaptchaProvider>
  )
}